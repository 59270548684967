import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'

let routes = [
    {
        path: '/',
        name: 'Home',
        alias: ['/Home'],
        component: () => import("@/views/Home.vue"),
        meta: {
          title: 'Home',
          accountNeeded : true,
        }
    },
    {
      path: '/Login/:token?',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
      meta: {
        title: 'Login',
        accountNeeded : false,
      }
    },
    {
      path: '/NoCurrentApp',
      name: 'NoCurrentApp',
      component: () => import('@/views/errors/NoCurrentApp.vue'),
      meta: {
        title: 'NoCurrentApp',
        accountNeeded : false,
      }
    },
    {
      path: '/BadVersion',
      name: 'BadVersion',
      component: () => import('@/views/errors/BadVersion.vue'),
      meta: {
        title: 'BadVersion',
        accountNeeded : false,
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'pathMatch(.*)*',
      component: () => import('@/views/errors/Error404.vue'),
      meta: {
        title: 'Error404',
        accountNeeded : false,
      }
    },
]

let router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(to => {
  if (['Login'].includes(to.name)) {
    getAuth().signOut()
  }
})

router.afterEach((to, from) => {
	nextTick(() => {
		document.title = 'Valy - ' + (to.meta.title ? to.meta.title : to.name)
	})
})

export default router
