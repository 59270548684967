import colors from 'vuetify/lib/util/colors'

let themeConfig = {
    dark: true,
    colors: {
        background: '#101016',
        surface: '#101622',
        primary: '#1793D1',
        secondary: '#3d4e66',
        accent: '#F1C972',
        error: colors.red.accent3,
        info: colors.lime.accent3,
        success: colors.green.accent3,
        warning: colors.deepOrange.lighten1,
    },
}

export default themeConfig;