import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"
import getCollectionPath from '@/assets/functions/getCollectionPath'

const db = getFirestore()
const collectionName = 'settings/'

function docToInstance(doc) {
  const data = doc.data()
  return data ? new Settings(doc.id, data.user, data.notificationSound, data.volume, data.wallpaper) : null
}

export default class Settings {
  constructor(id, user, notificationSound, volume, wallpaper) {
    this.id = id
    this.user = user
    this.notificationSound = notificationSound
    this.volume = volume
    this.wallpaper = wallpaper
  }

  static initOne(user){
    let settings = new Settings(null, user, 1, 50, 1)
    return settings
  }

  async save() {
    const new_settings = {
      user: this.user,
      notificationSound: this.notificationSound,
      volume: this.volume,
      wallpaper: this.wallpaper,
    }

    if (this.id) {
      await updateDoc(doc(db, await getCollectionPath(collectionName), this.id), new_settings)
    } else {
      await addDoc(collection(db, await getCollectionPath(collectionName)), new_settings)
    }
  }

  static async listenById(id, callback) {
    return onSnapshot(doc(db, await getCollectionPath(collectionName), id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }

  static async listenByUser(userId, callback) {
    return onSnapshot(query(collection(db, await getCollectionPath(collectionName)), where("user", "==", userId)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }

  static async listenAll(callback) {
    onSnapshot(collection(db, await getCollectionPath(collectionName)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }
  static async getById(id) {
    let document = await getDoc(doc(db, collectionName, id))
    return docToInstance(document)
  }

  static async getByUser(userId,){
    let response = await getDocs(query(collection(db, await getCollectionPath(collectionName)), where("user", "==", userId)))
    let list = []
    response.forEach(doc => {
        list.push(docToInstance(doc))
    })
    return list
  }

  static async getAll(){
    let response = await getDocs(collection(db, await getCollectionPath(collectionName)))
    let list = []
    response.forEach(doc => {
        list.push(docToInstance(doc))
    })
    return list
}


  async delete(){
      let documentRef = doc(db, await getCollectionPath(collectionName), this.id)
      deleteDoc(documentRef)
  }


}
